import React, { Component } from 'react';
import { Field, FieldArray } from 'formik';
import { Row, Col, Form, Image, Modal, Alert, Button } from 'react-bootstrap';
import {
  NewdoorCategory,
  NewdoorCategoryTypes,
  NewDoorHandle,
  NewDoorHandleFields,
} from 'constants/newDoors';
import { PropTypes } from 'prop-types';
import { isRequired } from 'utils/rules';
import FormInput from 'components/FormInputs';
import TabActions from 'components/TabActions';
import DropZone from 'components/DropZone';
import { Colors } from 'modules/theme';
import icon from 'assets/media/images/Group.png';
import Sketch from 'components/Sketch';
import GlassRemindersView from './GlassRemindersView';
import GlassMaterialsView from './GlassMaterialsView';
import { newdoorCategoryLayout } from '../../constants/doorTypeOptions';

const HANDLES = {
  ndHandleA: '',
  holesizeA: '',
  notesA: '',
  ndHandleB: '',
  holesizeB: '',
  notesB: '',
  ndHandleC: '',
  holesizeC: '',
  notesC: '',
  ndHandleD: '',
  holesizeD: '',
  notesD: '',
  ndHandleE: '',
  holesizeE: '',
  notesE: '',
  ndHandleF: '',
  holesizeF: '',
  notesF: '',
  ndHandleG: '',
  holesizeG: '',
  notesG: '',
  ndHandleH: '',
  holesizeH: '',
  notesH: '',
  ndHandleI: '',
  holesizeI: '',
  notesI: '',
};
const DETAILS = {
  gpMeasurements: '',
  doorWidth: '',
  doorHeight: '',
  transomHeight: '',
  transom: '',
  noteTransom: '',
  gpA: '',
  gpA_Angle: '',
  gpAOutage: '',
  gpANote: '',
  gpB: '',
  gpB_Angle: '',
  gpBOutage: '',
  gpBNote: '',
  gpC: '',
  gpC_Angle: '',
  gpCOutage: '',
  gpCNote: '',
  gpD: '',
  gpD_Angle: '',
  gpDOutage: '',
  gpDNote: '',
  gpE: '',
  gpE_Angle: '',
  gpEOutage: '',
  gpENote: '',
  gpF: '',
  gpF_Angle: '',
  gpFOutage: '',
  gpFNote: '',
  gpG: '',
  gpG_Angle: '',
  gpGOutage: '',
  gpGNote: '',
  gpH: '',
  gpH_Angle: '',
  gpHOutage: '',
  gpHNote: '',
  gpI: '',
  gpI_Angle: '',
  gpIOutage: '',
  gpINote: '',
  gpJ: '',
  gpJ_Angle: '',
  gpJOutage: '',
  gpJNote: '',
  gpK: '',
  gpK_Angle: '',
  gpKOutage: '',
  gpKNote: '',
  gpL: '',
  gpL_Angle: '',
  gpLOutage: '',
  gpLNote: '',
  gpM: '',
  gpM_Angle: '',
  gpMOutage: '',
  gpMNote: '',
  gpN: '',
  gpN_Angle: '',
  gpNOutage: '',
  gpNNote: '',
  gpO: '',
  gpO_Angle: '',
  gpOOutage: '',
  gpONote: '',
  gpP: '',
  gpP_Angle: '',
  gpPOutage: '',
  gpPNote: '',
  doorWidthAluminium: '',
  doorHeightAluminium: '',
  transomHeightAluminium: '',
  transomType: '',
  panelsWide1: '',
  panelsWide2: '',
  panelsWide3: '',
  panelsWide4: '',
  gpAngle1: '',
  gpAngle1Joints: '',
  gpAngle1Note: '',
  gpAngle2: '',
  gpAngle2Joints: '',
  gpAngle2Note: '',
  gpAngle3: '',
  gpAngle3Joints: '',
  gpAngle3Note: '',
  gpAngle4: '',
  gpAngle4Joints: '',
  gpAngle4Note: '',
  gpAngle5: '',
  gpAngle5Joints: '',
  gpAngle5Note: '',
  frameStyle: '',
  noteFrameStyle: '',
  doorAction: '',
  noteDoorAction: '',
  glassType: '',
  noteGlassType: '',
  closersJ: '',
  noteClosers: '',
  handlesL: '',
  noteHandles: '',
  locksK: '',
  noteLocks: '',
  thresholdN: '',
  noteThreshold: '',
  glassCoating: '',
  noteGlassCoating: '',
  header: '',
  noteheader: '',
  hardwareFinish: '',
  noteHardwareFinish: '',
  doorstyleA: '',
  doorstyleH: '',
  gpNoteDoorStyle: '',
  framesystemJ: '',
  gpNoteFrameSystem: '',
  doorActionE: '',
  doorActionENote: '',
  gpNoteDoorAction: '',
  hanginghardwareL: '',
  gpNoteHangingHardware: '',
  doorcloserO: '',
  gpNoteDoorCloser: '',
  lockP: '',
  gpLookNote: '',
  panicdeviceQ: '',
  gpPanicDeviceNote: '',
  toprailtypeG: '',
  noteTopRail: '',
  bottomrailW: '',
  bottomrailtypeG: '',
  gpNoteBottomRail: '',
  sidelitesconfigQ: '',
  noteSidelites: '',
  sidelitesconfigB: '',
  noteSidelitesB: '',
  midpanelU: '',
  gpMidPanelNote: '',
  hmdweatherStrippingJ: '',
  gpWeatherStrippingNote: '',
  handle: '',
  noteHandle: '',
  fixedPanelSecuring: '',
  noteFixedPanelSecuring: '',
  // Single Door
  measureA: '',
  measureB: '',
  measureFA: '',
  measureFB: '',
  dooropeningWA: '',
  walltypeA: '',
  dooropeningHB: '',
  roughopeningWC: '',
  roughopeningHD: '',
  roughopeningB: '',
  roughOpeningWB: '',
  roughOpeningHB: '',
  roughopeningC: '',
  doorOpeningWC: '',
  doorOpeningHC: '',
  roughopeningD: '',
  roughopeningE: '',
  roughopeningF: '',
  doorsizeWD: '',
  doorsizeHD: '',
  doorWidthE: '',
  offsetarmEONE: '',
  frametype: '',
  pdFrameTNote: '',
  framingD: '',
  pdFramingDNote: '',
  thermallyB: '',
  pdThermallyBNote: '',
  frame: '',
  pdFramNote: '',
  doorWF: '',
  doorHG: '',
  walldepththicknessG: '',
  doorWA: '',
  doorHB: '',
  topofdoortophinge_1: '',
  topofdoortophinge_2: '',
  topofdoortophinge_3: '',
  topofdoortophinge_4: '',
  topofdoorscenterlinedeadbolt: '',
  topofdoorscenterlinelock: '',
  glassTypeF: '',
  toppatchG: '',
  bottompatchG: '',
  toprailsizeG: '',
  bottomrailsizeG: '',
  framewidth: '',
  glass: '',
  lockbacksetI: '',
  metalfinishI: '',
  ndhmdDoorType: '',
  ndhmdDoorTypeNote: '',
  ndhmdDoorAction: '',
  ndhmdDoorActionNote: '',
  hingeheightJ: '',
  ndhmdJNote: '',
  doorthickK: '',
  ndhmdKNote: '',
  ndhmdDoorPanel: '',
  ndhmdDoorPanelNote: '',
  ndhmdHingeSize: '',
  ndhmdHingeSizeNote: '',
  ndhmdN: '',
  ndhmdNNote: '',
  finishK: '',
  contHingeL: '',
  ndhmdLNote: '',
  doorhandM: '',
  ndhmdMNote: '',
  topdoorcentermidpanelV: '',
  topdoorcenterindermediateM: '',
  headerM: '',
  fireratingN: '',
  visionkitO: '',
  ndhmdONote: '',
  ndhmdLouver: '',
  ndhmdLouverNote: '',
  ndhmdDoorFrameFinish: '',
  ndhmdDoorFrameFinishNote: '',
  ndhmdDoorHardwareFinish: '',
  ndhmdDoorHardwareFinishNote: '',
  ndhmdDoorClosers: '',
  ndhmdDoorCloserNote: '',
  ndhmdRoomType: '',
  ndhmdRoomTypeNote: '',
  ndhmdLocksetFunction: '',
  ndhmdLocksetFunctionNote: '',
  ndhmdDeadBolt: '',
  ndhmdDeadBoltNote: '',
  panicdeviceR: '',
  ndhmdRNote: '',
  kickplate: '',
  ndhmdVNote: '',
  ndhmdDoorSweepWeatherStripping: '',
  ndhmdDoorSweepWeatherStrippingNote: '',
  ndhmdFrameProfile: '',
  ndhmdFrameProfileNote: '',
  ndhmdFrameThickness: '',
  ndhmdFrameThicknessNote: '',
  ndhmdFrameFabrication: '',
  ndhmdFrameFabricationNote: '',
  ndhmdFrameInstallType: '',
  ndhmdFrameInstallTypeNote: '',
  sideheadconditionO: '',
  sidesillconditionO: '',
  locksetP: '',
  ndhmdPNote: '',
  deadboltButtonQ: '',
  frameR: '',
  pdFrameRNote: '',
  topdoorcenterlockpanicR: '',
  doorcloserS: '',
  ndhmdSNote: '',
  handlesS: '',
  pullset: '',
  ndhmdTNote: '',
  topdoorcentertoppullT: '',
  latchguardButtonU: '',
  doorsweepButtonW: '',
  doorviewerButtonY: '',
  dripcapButtonX: '',
  doorsweepsX: '',
  framdepthZ: '',
  assem: '',
  pdAssemNote: '',
  finishA: '',
  pdFinANote: '',
  finishB: '',
  pdFinBNote: '',
  glassthick: '',
  pdGlassThickNote: '',
  glassC: '',
  pdGlassCNote: '',
  grid: '',
  pdGridNote: '',
  hardwareC: '',
  pdHardwareCNote: '',
  standardHard: [],
};

const INITIAL_VALUE = {
  glassReminders: {
    solar_film: 'no',
    solar_film_option_sag: null,
    wet_seal: 'no',
    wet_seal_option: null,
    furniture: 'no',
    walls_cielings: 'no',
    walls_cielings_option: null,
    blind_remove: 'no',
    glass_elevator: 'yes',
    color_waiver: 'no',
    damage_waiver: 'no',
    disclaimers: 'no',
    lift_inside: 'no',
    lift_outside: 'no',
  },
  glassMaterials: {
    caulks: [{ amount: 0, value: '', notes: '' }],
    types: [{ amount: 0, value: '', notes: '' }],
    equipaments: [{ amount: 0, value: '', notes: '' }],
    channels: [{ amount: 0, value: '', notes: '' }],
    miscellaneous: [{ amount: 0, value: '', notes: '' }],
  },
  details: DETAILS,
  handles: HANDLES,
  images: [],
  sketchs: [],
};

export default class NewDoorsView extends Component {
  constructor(props) {
    super(props);
    this.state = { current: 0, showEraseAlert: false };
  }

  static propTypes = {
    errors: PropTypes.object.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
  };

  addValue = (field, value) => {
    const { setFieldValue } = this.props;
    setFieldValue(field, parseInt(value || 0, 10) + 1);
  };

  substractValue = (field, value) => {
    const { setFieldValue } = this.props;
    setFieldValue(field, parseInt(value || 0, 10) - 1);
  };

  erase = remove => {
    const { current } = this.state;
    remove(current);
    this.setState({ showEraseAlert: false, current: current - 1 });
  };

  viewItem = current => {
    this.setState({ current });
  };

  renderAlert = (title, content, show, handleClose, successText, success) => (
    <Modal show={show} onHide={handleClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{content}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={success}>
          {successText}
        </Button>
      </Modal.Footer>
    </Modal>
  );

  renderNewDoor = (newDoor, key, form, push, remove, errors = {}, touched = {}) => {
    const { handleChange, handleBlur, setFieldValue, token } = this.props;

    return (
      <Row className="mb-4 ml-0 mr-0 pt-5" style={{ background: '#fff' }}>
        <Col xs={12} sm={5} md={5} xl={5}>
          <Form.Group controlId={`newDoors.${key}.category`}>
            <Form.Label>
              <h3>Category</h3>
            </Form.Label>
            <Field
              as="select"
              className={
                errors.category && touched.category
                  ? 'form-control is-invalid is-invalid-select'
                  : 'form-control'
              }
              name={`newDoors.${key}.category`}
              value={newDoor.category || ''}
              validate={isRequired}
              onChange={evt => {
                const { value } = evt.target;
                setFieldValue(`newDoors.${key}.type`, value === 'films' ? value : null);
                setFieldValue(`newDoors.${key}.details`, DETAILS);
                setFieldValue(`newDoors.${key}.type_picture`, null);
                handleChange(evt);
              }}
              onBlur={handleBlur}
              required
            >
              <option value="">Choose a type</option>
              {NewdoorCategory.map(({ label, value }, index) => (
                <option value={value} key={index}>
                  {label}
                </option>
              ))}
            </Field>
            <Form.Control.Feedback type="invalid">
              {errors.category && touched.category && errors.category}
            </Form.Control.Feedback>
          </Form.Group>
          {newDoor.category && (
            <div>
              {newDoor.details.picture && (
                <Image
                  src={`${newDoor.details.picture}`}
                  style={{ margin: '20px 0px', padding: '10px' }}
                  thumbnail
                />
              )}
              <Form.Group controlId={`newDoors.${key}.instructions`}>
                <Form.Label>
                  <h3>Instructions</h3>
                </Form.Label>
                <Field
                  as="textarea"
                  className="textarea"
                  name={`newDoors.${key}.instructions`}
                  value={newDoor.instructions || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  rows={3}
                />
              </Form.Group>
            </div>
          )}
        </Col>
        <Col xs={12} sm={7} md={7} xl={7}>
          {newDoor.category && newDoor.category !== 'films' && (
            <div
              className=" d-flex justify-content-start align-items-end mt-5"
              onClick={() => setFieldValue(`newDoors.${key}.details.layout`, true)}
            >
              <h2
                className="pointer input-text"
                style={{
                  border: 'solid 1px',
                  padding: '8px',
                  borderColor: Colors.gray2,
                  borderRadius: '3px',
                }}
              >
                <Image src={icon} style={{ width: '25px', height: '25px', marginRight: '5px' }} />
                Choose Layout
              </h2>
            </div>
          )}
          {newDoor.category && (
            <>
              <Row>
                {NewdoorCategoryTypes[newDoor.category].fields[newDoor.category].fields.map(
                  (
                    {
                      name,
                      name2,
                      name3,
                      name4,
                      type,
                      colInputWithButtonsLg,
                      label,
                      details,
                      angles,
                      options,
                      placeholder,
                      style,
                    },
                    index,
                  ) => (
                    <Col xs={12} sm={12} md={6} xl={6} key={index}>
                      <FormInput
                        name={`newDoors.${key}.details.${name}`}
                        placeholder={placeholder}
                        type={type}
                        label={label}
                        details={details}
                        angles={angles}
                        name2={`newDoors.${key}.details.${name2}`}
                        name3={`newDoors.${key}.details.${name3}`}
                        name4={`newDoors.${key}.details.${name4}`}
                        options={options}
                        colInputWithButtonsLg={colInputWithButtonsLg}
                        value={newDoor.details[name] || ''}
                        value2={newDoor.details[name2] || ''}
                        value3={newDoor.details[name3] || ''}
                        value4={newDoor.details[name4] || ''}
                        detailName={`newDoors.${key}.details.${name}_detail`}
                        detailValue={newDoor.details[`${name}_detail`]}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        setFieldValue={setFieldValue}
                        key={index}
                        style={style}
                      />
                    </Col>
                  ),
                )}
              </Row>
            </>
          )}
        </Col>
        {newDoor.category && newDoor.type && ['Gdoor'].includes(newDoor.category) && (
          <Col xs={12} md={12} sm={12} xl={12}>
            <Row>
              <Col xs={12} sm={12} md={5} xl={5}>
                <Form.Group controlId={`newDoors.${key}.handle`}>
                  <Form.Label>
                    <h3>Handle type</h3>
                  </Form.Label>
                  <Field
                    as="select"
                    className={
                      errors.handle && touched.handle
                        ? 'form-control is-invalid is-invalid-select'
                        : 'form-control'
                    }
                    name={`newDoors.${key}.handle`}
                    value={newDoor.handle || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="">Choose a handle</option>
                    {NewDoorHandle.map(({ label, value }, index) => (
                      <option value={value} key={index}>
                        {label}
                      </option>
                    ))}
                  </Field>
                  <Form.Control.Feedback type="invalid">
                    {errors.handle && touched.handle && errors.handle}
                  </Form.Control.Feedback>
                </Form.Group>
                {newDoor.handle && (
                  <Image
                    src={NewDoorHandleFields[newDoor.handle].picture}
                    style={{ margin: '20px 0px', padding: '10px' }}
                    thumbnail
                  />
                )}
              </Col>
              <Col xs={12} sm={12} md={7} xl={7}>
                {newDoor.handle && (
                  <Row>
                    {NewDoorHandleFields[newDoor.handle].fields.map(
                      ({ name, name2, name3, type, label, details, options }, index) => (
                        <Col xs={12} sm={12} md={12} xl={12} key={index}>
                          <FormInput
                            name={`newDoors.${key}.handles.${name}`}
                            name2={`newDoors.${key}.handles.${name2}`}
                            name3={`newDoors.${key}.handles.${name3}`}
                            type={type}
                            label={label}
                            details={details}
                            options={options}
                            value={newDoor.handles[name] || ''}
                            value2={newDoor.handles[name2] || ''}
                            value3={newDoor.handles[name3] || ''}
                            isInvalid={
                              errors.handles && touched.handles
                                ? !!errors.handles[name] && touched.handles[name]
                                : false
                            }
                            isInvalidDetails={
                              errors.handles && touched.handles
                                ? !!errors.handles[`${name}_detail`] &&
                                  touched.handles[`${name}_detail`]
                                : false
                            }
                            detailName={`newDoors.${key}.handles.${name}_detail`}
                            detailValue={newDoor.handles[`${name}_detail`]}
                            error={errors.handles ? errors.handles[name] : null}
                            validate={isRequired}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Col>
                      ),
                    )}
                  </Row>
                )}
              </Col>
            </Row>
          </Col>
        )}
        {newDoor.category && newDoor.type && (
          <Col xs={12} md={12} sm={12} xl={6} style={{ marginTop: '40px' }}>
            <GlassRemindersView
              prefix={`newDoors.${key}.glassReminders`}
              values={newDoor.glassReminders}
              errors={errors.glassReminders || {}}
              handleChange={handleChange}
              touched={touched.glassReminders || {}}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
            />
          </Col>
        )}
        {newDoor.category && newDoor.type && (
          <Col xs={12} md={12} sm={12} xl={6} style={{ marginTop: '40px' }}>
            <GlassMaterialsView
              prefix={`newDoors.${key}.glassMaterials`}
              values={newDoor.glassMaterials}
              errors={errors.glassMaterials || {}}
              handleChange={handleChange}
              touched={touched.glassMaterials || {}}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
            />
          </Col>
        )}

        <Col xs={12} sm={12} md={12} xl={12} className="p-0">
          <h3>Images</h3>
          <DropZone
            height={300}
            token={token}
            folder="new-doors-uploads"
            initialFiles={newDoor.images}
            handleSuccess={({ url }, position) => {
              const images = [...newDoor.images];
              const pos = Math.min(position, newDoor.images.length);
              images.splice(pos, 0, url);
              setFieldValue(`newDoors.${key}.images`, images);
            }}
            handleRemove={({ url }) =>
              setFieldValue(
                `newDoors.${key}.images`,
                newDoor.images.filter(u => u !== url),
              )
            }
            handlePosition={(fromIndex, toIndex) => {
              const images = [...newDoor.images];
              const element = images[fromIndex];
              images.splice(fromIndex, 1);
              images.splice(toIndex, 0, element);

              setFieldValue(`newDoors.${key}.images`, images);
            }}
          />
        </Col>
        <Col xs={12} sm={12} md={12} xl={12} className="mt-5 pt-3" style={{ cursor: 'cell' }}>
          <h3>Sketches</h3>
          <Form.Label>Click below to sketch </Form.Label>
          <Sketch
            token={token}
            folder="new-doors-sketchs"
            sketchs={newDoor.sketchs}
            handleSuccess={({ url }) => {
              setFieldValue(`newDoors.${key}.sketchs`, [...newDoor.sketchs, url]);
            }}
            handleRemove={({ url }) =>
              setFieldValue(
                `newDoors.${key}.sketchs`,
                newDoor.sketchs.filter(u => u !== url),
              )
            }
          />
        </Col>

        <Modal
          show={newDoor.details.layout === true}
          onHide={() => setFieldValue(`newDoors.${key}.details.layout`, false)}
          title="Select Layout"
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Choose Layout</Modal.Title>
          </Modal.Header>
          {newDoor.details.title && (
            <Row className="d-flex justify-content-center align-items-center py-4">
              <h3>Active Layout: </h3>
              <h3 style={{ color: '#0084EA' }}>{newDoor.details.title}</h3>
            </Row>
          )}
          <div>
            {newDoor.category && (
              <Field
                as="select"
                className={
                  errors.type && touched.type
                    ? 'form-control is-invalid is-invalid-select'
                    : 'form-control'
                }
                name={`newDoors.${key}.details.option`}
                value={newDoor.details.option || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              >
                <option value="">Choose a type</option>
                {newdoorCategoryLayout[newDoor.category].types.map(({ label, value }, index) => (
                  <option value={value} key={index}>
                    {label}
                  </option>
                ))}
              </Field>
            )}

            <Col xs={12} sm={12} md={12} xl={12}>
              {newDoor.details.option && (
                <Row className="justify-content-center">
                  {newdoorCategoryLayout[newDoor.category].fields[newDoor.details.option] &&
                    newdoorCategoryLayout[newDoor.category].fields[
                      newDoor.details.option
                    ].values.map(({ label, value, int, option }, index) => (
                      <div
                        key={index}
                        className="pointer m-2 d-flex align-items-center"
                        onClick={() => {
                          setFieldValue(`newDoors.${key}.details.picture`, value);
                          setFieldValue(`newDoors.${key}.type_picture`, int);
                          setFieldValue(`newDoors.${key}.details.title`, label);
                          setFieldValue(`newDoors.${key}.details.layout`, false);
                          setFieldValue(`newDoors.${key}.details.option`, option);
                          setFieldValue(`newDoors.${key}.type`, newDoor.category);
                          setFieldValue(`newDoors.${key}.handles`, HANDLES);
                        }}
                        style={
                          value !== newDoor.details.picture
                            ? { flexDirection: 'column' }
                            : {
                                flexDirection: 'column',
                                border: 'solid 2px',
                                borderColor: '#0084EA',
                              }
                        }
                      >
                        <p className="mb-0" style={{ alignItems: 'end', flexDirection: 'column' }}>
                          {label}
                        </p>
                        <Image
                          src={`${value}`}
                          style={{
                            width: '150px',
                            height: '150px',
                            border: 'none',
                          }}
                          thumbnail
                        />
                      </div>
                    ))}
                </Row>
              )}
            </Col>
          </div>
        </Modal>
      </Row>
    );
  };

  render() {
    const {
      values: { newDoors },
      errors,
      touched,
    } = this.props;
    const { current, showEraseAlert } = this.state;
    const newDoorsCount = newDoors.length;
    const newDoor = newDoorsCount > 0 ? newDoors[current] : null;

    return (
      <div>
        <FieldArray
          name="newDoors"
          render={({ form, push, remove }) => (
            <div style={{ marginBottom: '40px' }} key="newDoors">
              <TabActions
                name="New"
                count={newDoorsCount}
                current={current}
                add={() => {
                  this.setState({ current: newDoorsCount }, () => {
                    push(INITIAL_VALUE);
                  });
                }}
                ss={() => {}}
                s={() => {
                  this.setState({ current: newDoorsCount }, () => {
                    push({
                      ...INITIAL_VALUE,
                      type: newDoor.type,
                      category: newDoor.category,
                      details: newDoor.details,
                    });
                  });
                }}
                copy={() => {
                  this.setState({ current: newDoorsCount }, () => {
                    push({ ...newDoor });
                  });
                }}
                erase={() => this.setState({ showEraseAlert: true })}
                viewItem={this.viewItem}
              />
              {newDoor ? (
                this.renderNewDoor(
                  newDoor,
                  current,
                  form,
                  push,
                  remove,
                  errors && errors.newDoors ? errors.newDoors[current] : {},
                  touched && touched.newDoors ? touched.newDoors[current] : {},
                )
              ) : (
                <Alert variant="light" style={{ paddingTop: 20, background: '#F8FBFF' }}>
                  <Alert.Heading style={{ textAlign: 'center' }}>
                    "There is currently no New"{' '}
                    <Alert.Link
                      onClick={() => {
                        this.setState({ current: newDoorsCount }, () => {
                          push(INITIAL_VALUE);
                        });
                      }}
                    >
                      Click here
                    </Alert.Link>
                    {' to create a new'}
                  </Alert.Heading>
                </Alert>
              )}
              {/* Alerts */}
              {this.renderAlert(
                'Remove',
                'Are you sure that want remove?',
                showEraseAlert,
                () => this.setState({ showEraseAlert: false }),
                'Remove',
                () => this.erase(remove),
              )}
            </div>
          )}
        />
      </div>
    );
  }
}
